<template>
	<div>
    <template>
      <div class="items-grid-view vx-row match-height">
        <div class="vx-col lg:w-1/6 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="item in items.slice(0, (18*currentx))" :key="item.id">

          <item-grid-view :item="item"></item-grid-view>

        </div>
      </div>
      <infinite-loading ref="infiniter" @infinite="loadMore">
        <template slot="no-results">
          No result/No more item
        </template>
      </infinite-loading>
    </template>
  </div>
</template>
<script>
  import InfiniteLoading from 'vue-infinite-loading';
  export default {
    components: {
      ItemGridView: () => import("./components/ItemGridView.vue"),
      InfiniteLoading,
    },
    data (){
      return{
        items: this.$store.state.eCommerce.wishList,
        currentx:1,
      }
    },
    methods: {
      loadMore($state){
        if (this.currentx < this.totalPage){
          this.currentx++;
          setTimeout(() => {
            $state.loaded();
          }, 1000);
        }else{
          $state.complete();
        }
      }
    }
  }
</script>
